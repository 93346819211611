<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="请输入流水号/渠道流水" clearable></el-input>
				</el-form-item>
				<el-form-item  v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item >
					<el-select v-model="params.model" placeholder="请选择消费渠道" filterable clearable>
						<el-option v-for="(model, m) in models" :key="m" :label="model" :value="m"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item >
					<el-select v-model="params.type" placeholder="请选择交易类型" filterable clearable>
						<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item >
					<el-select v-model="params.payment" placeholder="请选择支付渠道" filterable clearable>
						<el-option v-for="(method, m) in methods" :key="m" :label="method" :value="m"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="请选择账单状态" filterable clearable>
						<el-option v-for="(status, s) in statues" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" style="width: 100px;" v-model="params.at" placeholder="时间类型">
						<el-option label="支付时间" value="paid"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
						<el-option label="创建时间" value="created"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getBills({ ...params, page: 1})">查询</el-button>
					<!-- <el-button type="primary" @click="onExport">导出选中</el-button> -->
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="wallet_table" class="bill scroll-wrapper absolute" height="100%" :data="bills" :size="theme.size" :row-class-name="billTableRowClassName">
				<el-table-column fixed="left" type="selection" width="42"></el-table-column>
				<el-table-column fixed="left" prop="no" label="流水号"></el-table-column>
				<el-table-column prop="creator.name" label="用户">
					<template slot-scope="scope">{{scope.row.creator.name}}<template v-if="!admin_user.shop_id">@{{scope.row.shop ? scope.row.shop.name : theme.group}}</template></template>
				</el-table-column>
				<el-table-column prop="common_model_type" label="消费渠道" width="80" :formatter="(r) => { return models[r.common_model_type] || '未知'; }"></el-table-column>
				<el-table-column prop="type" label="交易类型" width="80" :formatter="(r) => { return types[r.type] || '未知'; }"></el-table-column>
				<el-table-column prop="payment" label="支付渠道" width="80" :formatter="(r) => { return methods[r.payment] || '未知'; }"></el-table-column>
				<el-table-column prop="paid_no" label="支付流水" :formatter="(r) => { return r.paid_no || '暂无 ~' }"></el-table-column>
				<el-table-column prop="price" label="金额" width="100" :formatter="(r) => { return (r.price/100).toFixed(2) + ' 元'; }"></el-table-column>
				<el-table-column prop="status" label="状态" width="80" :formatter="(r) => { return statues[r.status] || '未知'; }"></el-table-column>
				<el-table-column prop="at" label="操作时间" width="210">
					<template slot-scope="scope">
						<p>更新：{{scope.row.updated_at}}</p>
						<p>创建：{{scope.row.created_at}}</p>
						<p v-if="scope.row.paid_at">支付：{{scope.row.paid_at}}</p>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getBills({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getBills({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
	</div>
</template>

<style>
	.el-table.bill .info-row {
		background: #f4f4f5;
	}
	.el-table.bill .danger-row {
		background: #fef0f0;
	}
	.el-table.bill .warning-row {
		background: #fdf5e6;
	}
	.el-table.bill .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			billTableRowClassName ({row}) {
				switch (row.status) {
					case 'closed':
						return 'info-row';
					case 'paying':
						return 'warning-row';
					case 'payed':
						return 'success-row';
					default:
						return '';
				}
			},
			onPay (r) {
				this.$confirm('此操作将直接转账到用户'+r.payment_name+', 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.post(this.$api.URI_BILLS, r);
					if (res.data.code != 0) return this.$message.error(res.data.msg);
					this.$message.success(res.data.msg);
				});
			},
			onExport () {
				const { selection } = this.$refs.wallet_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				this.$confirm('此操作将导出' + selection.length + '条内容, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var file = "账单明细导出" + this.$moment().format('YYYYMMDDHHmmss');
					var header = [['ID', '流水号', '用户', '交易类型', '交易渠道', '渠道流水', '金额', '状态', '备注', '更新时间', '创建时间']];
					var filter = ['id', 'no', 'user_name', 'type_name', 'payment_name', 'paid_no', 'price', 'status_name', 'note', 'updated_at', 'created_at'];
					this.$utils.handleDownload(header, filter, selection, file, "账单明细");
				});
			},
			async getBills (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_BILLS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.bills = result.data;
				this.types = result.types;
				this.shops = result.shops;
				this.models = result.models;
				this.statues = result.status;
				this.methods = result.methods;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				bills: [],
				shops: [],
				types: [],
				models: [],
				methods: [],
				statues: [],
				params: {
					perPage: 10,
					at: 'paid'
				}
			}
		},
		created () {
			this.getBills(this.params, true);
		}
	};
</script>